import React, {useState, useEffect} from 'react';
import queryString from "query-string";
import {Link} from "react-router-dom";
import FecthingSpinner from "../Shared/FecthingSpinner";
import httpService from "../../services/http.service";
import {
    formatCash, getUserFullName, numberWithSeparator,
    PERIODICITY_DAILY,
    PERIODICITY_MONTHLY,
    PERIODICITY_WEEKLY,
    PERIODICITY_YEARLY
} from "../../utils/index";
import MyOpinionLogo from "../../assets/images/MyOpinion_Logo_Head.png";
import AppBreadcrumb from "../Shared/AppBreadcrumb";
import useAlert from "../../hooks/useAlert";
import AppAlert from "../Shared/AppAlert";
import {connect} from "react-redux";

const Dashboard = ({authUser, history, location, match, ...props}) => {

    const query = queryString.parse(location.search);

    const fetchActiveUsers = async (cb = () => null) => {
        const { data: response } = await httpService.get(`/stats/activeUsers`);
        cb(response);
        return response;
    }

    const fetchNewUsers = async (cb = () => null) => {
        const { data: response } = await httpService.get(`/stats/newUsers`);
        cb(response);
        return response;
    }

    const fetchTotalAuthorizationRequests = async (cb = () => null) => {
        const { data: response } = await httpService.get(`/stats/totalAuthorizationRequests`);
        cb(response);
        return response;
    }

    const fetchTotalUsers = async (cb = () => null) => {
        const { data: response } = await httpService.get(`/stats/totalUsers`);
        cb(response);
        return response;
    }

    const fetchActiveOpinions = async (cb = () => null) => {
        const { data: response } = await httpService.get(`/stats/activeOpinions`);
        cb(response);
        return response;
    }

    const fetchMostFollowedUsers = async (cb = () => null) => {
        const { data: response } = await httpService.get(`/stats/mostFollowedUser`);
        cb(response);
        return response;
    }

    const navigateToUserProfile = (user) => {
        history.push(`/users/${user?.user_username}`);
    }

    const initialValues = {}

    const [fetching, setFetching] = useState(true);
    const [activeUsers, setActiveUsers] = useState(null);
    const [newUsers, setNewUsers] = useState(null);
    const [activeOpinions, setActiveOpinions] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [mostFollowedUsers, setMostFollowedUsers] = useState([]);
    const [authorisationRequestsAlert, setAuthorisationRequestsAlert, onClose] = useAlert();

    const getVariationClassName = (value) => {
        if(value == 0) return '';
        if(value > 0) return 'text-success';
        if(value < 0) return 'text-danger';
    }

    const getVariationCaret = (value) => {
        if(value == 0) return null;
        if(value > 0) return <i className="fa fa-caret-up"></i>
        if(value < 0) return <i className="fa fa-caret-down"></i>
    }

    useEffect(() => {

        try {

            Promise.all([
                fetchActiveUsers(),
                fetchNewUsers(),
                fetchTotalUsers(),
            ]).then((responses) => {
                setActiveUsers(responses[0]);
                setNewUsers(responses[1]);
                setTotalUsers(responses[2]);
                setFetching(false);
            });


        } catch (e) {
            throw e;
        }


    }, []);

    return (
        <>
            <div className="container-fluid">
                <AppBreadcrumb title="Tableau de bord"/>

                {fetching ? <FecthingSpinner /> : (
                    <>
                        <div className="row clearfix">

                            <div className="col-md-4">
                                <div className="card">
                                    <div className="header font-weight-bold"><h2>Nouveaux utilisateurs {process.env.REACT_APP_APP_NAME}</h2></div>
                                    <div className="body">
                                        <table className="table table-sm card-table mb-0">
                                            <tbody>
                                            <tr className="font-weight-bold">
                                                <td>Aujourd'hui</td>
                                                <td className="text-right">
                                                    {formatCash(newUsers[PERIODICITY_DAILY]['value'])}{' '}
                                                    <span className={getVariationClassName(newUsers[PERIODICITY_DAILY]['variation'])}>
                                                    ({newUsers[PERIODICITY_DAILY]['variation']}%)
                                                        {getVariationCaret(newUsers[PERIODICITY_DAILY]['variation'])}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr className="font-weight-bold">
                                                <td>Cette semaine</td>
                                                <td className="text-right">
                                                    {formatCash(newUsers[PERIODICITY_WEEKLY]['value'])}{' '}
                                                    <span className={getVariationClassName(newUsers[PERIODICITY_WEEKLY]['variation'])}>
                                                    ({newUsers[PERIODICITY_WEEKLY]['variation']}%)
                                                        {getVariationCaret(newUsers[PERIODICITY_WEEKLY]['variation'])}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr className="font-weight-bold">
                                                <td>Ce mois</td>
                                                <td className="text-right">
                                                    {formatCash(newUsers[PERIODICITY_MONTHLY]['value'])}{' '}
                                                    <span className={getVariationClassName(newUsers[PERIODICITY_MONTHLY]['variation'])}>
                                                    ({newUsers[PERIODICITY_MONTHLY]['variation']}%)
                                                        {getVariationCaret(newUsers[PERIODICITY_MONTHLY]['variation'])}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr className="font-weight-bold">
                                                <td>Cette année</td>
                                                <td className="text-right">
                                                    {formatCash(newUsers[PERIODICITY_YEARLY]['value'])}{' '}
                                                    <span className={getVariationClassName(newUsers[PERIODICITY_YEARLY]['variation'])}>
                                                    ({newUsers[PERIODICITY_YEARLY]['variation']}%)
                                                        {getVariationCaret(newUsers[PERIODICITY_YEARLY]['variation'])}
                                                </span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="card">
                                    <div className="header font-weight-bold"><h2>Utilisateurs {process.env.REACT_APP_APP_NAME} actifs</h2></div>
                                    <div className="body">
                                        <table className="table table-sm card-table mb-0">
                                            <tbody>
                                            <tr className="font-weight-bold">
                                                <td>Aujourd'hui</td>
                                                <td className="text-right">
                                                    {formatCash(activeUsers[PERIODICITY_DAILY]['value'])}{' '}
                                                    <span className={getVariationClassName(activeUsers[PERIODICITY_DAILY]['variation'])}>
                                                    ({activeUsers[PERIODICITY_DAILY]['variation']}%)
                                                        {getVariationCaret(activeUsers[PERIODICITY_DAILY]['variation'])}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr className="font-weight-bold">
                                                <td>Cette semaine</td>
                                                <td className="text-right">
                                                    {formatCash(activeUsers[PERIODICITY_WEEKLY]['value'])}{' '}
                                                    <span className={getVariationClassName(activeUsers[PERIODICITY_WEEKLY]['variation'])}>
                                                    ({activeUsers[PERIODICITY_WEEKLY]['variation']}%)
                                                        {getVariationCaret(activeUsers[PERIODICITY_WEEKLY]['variation'])}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr className="font-weight-bold">
                                                <td>Ce mois</td>
                                                <td className="text-right">
                                                    {formatCash(activeUsers[PERIODICITY_MONTHLY]['value'])}{' '}
                                                    <span className={getVariationClassName(activeUsers[PERIODICITY_MONTHLY]['variation'])}>
                                                    ({activeUsers[PERIODICITY_MONTHLY]['variation']}%)
                                                        {getVariationCaret(activeUsers[PERIODICITY_MONTHLY]['variation'])}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr className="font-weight-bold">
                                                <td>Cette année</td>
                                                <td className="text-right">
                                                    {formatCash(activeUsers[PERIODICITY_YEARLY]['value'])}{' '}
                                                    <span className={getVariationClassName(activeUsers[PERIODICITY_YEARLY]['variation'])}>
                                                    ({activeUsers[PERIODICITY_YEARLY]['variation']}%)
                                                        {getVariationCaret(activeUsers[PERIODICITY_YEARLY]['variation'])}
                                                </span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="card">
                                    <div className="header"><h2>Autres indicateurs</h2></div>
                                    <div className="body">
                                        <div className="row text-center">
                                            <div className="col-12 pb-4 pt-4" title={numberWithSeparator(totalUsers)}>
                                                <label className="mb-0">Utilisateurs</label>
                                                <h4 className="font-30 font-weight-bold text-col-blue">{formatCash(totalUsers)}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>

                )}
            </div>
        </>
    );

}

const mapStateToProps = (state) => ({
    authUser: state.auth.user
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
