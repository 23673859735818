import Login from './Authentication/login';
import ForgotPassword from './Authentication/forgotpassword';
import Maintenance from './Authentication/maintenance';
import UserList from "./Pages/User/list";
import UserDetails from "./Pages/User/details";
import OpinionDetails from "./Pages/Opinion/details";
import CommentList from "./Pages/Comment/list";
import Profile from "./Pages/profile";
import ModerateurList from "./Pages/Moderateur/list";
import Dashboard from "./Pages/dashboard";
import OpinionList from "./Pages/Opinion/list";

const Routes = [
    {
        path: "/",
        name: 'dashboard',
        exact: true,
        pageTitle: "Tableau de bord",
        component: Dashboard
    },
    {
        path: "/users",
        name: 'users',
        exact: true,
        pageTitle: "Users",
        component: UserList
    },
    {
        path: "/users/:id",
        name: 'user.details',
        exact: true,
        pageTitle: "Details",
        component: UserDetails
    },
    {
        path: "/users/:id/posts",
        name: 'user.opinions',
        exact: true,
        pageTitle: "Opinions",
        component: UserDetails
    },
    {
        path: "/users/:id/comments",
        name: 'user.comments',
        exact: true,
        pageTitle: "Comments",
        component: UserDetails
    },
    {
        path: "/opinions",
        name: 'opinions',
        exact: true,
        pageTitle: "Opinions",
        component: OpinionList
    },
    {
        path: "/opinions/:id",
        name: 'opinions.details',
        exact: true,
        pageTitle: "Opinions",
        component: OpinionDetails
    },
    {
        path: "/comments",
        name: 'comments',
        exact: true,
        pageTitle: "Commentaires",
        component: CommentList
    },
    {
        path: "/login",
        name: 'login',
        exact: true,
        pageTitle: "Login",
        component: Login
    },
    {
        path: "/profile",
        name: 'profile',
        exact: true,
        pageTitle: "Profile",
        component: Profile
    },
    {
        path: "/moderateurs",
        name: 'admins',
        exact: true,
        pageTitle: "Admin",
        component: ModerateurList
    },
    {
        path: "/forgotpassword",
        name: 'forgotpassword',
        exact: true,
        pageTitle: "Tables",
        component: ForgotPassword
    },
    {
        path: "/maintenance",
        name: 'maintenance',
        exact: true,
        pageTitle: "Maintenance",
        component: Maintenance
    },

];

export default Routes;