import React, {useEffect, useState} from 'react';
import httpService from "../../../services/http.service";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import {Link, Route, Switch} from "react-router-dom";
import MyOpinionLogo from "../../../assets/images/MyOpinion_Logo_Head.png";
import UserPosts from "./posts";
import UserProfile from "./profile";
import UserComments from "./comments";
import {getUserFullName} from "../../../utils/index";

const classnames = require('classnames');

const UserDetails = ({history, location, match, ...props}) => {

    const [user, setuser] = useState(null);
    const [fetching, setFetching] = useState(true);

    const onDelete = async () => {
        if(!window.confirm('Confirmer ?')) return;

        const { data: response } = await httpService.delete(`/users/${user.username}`);

        history.replace(`/users`)
    }

    const fetchUser = async (id, cb = () => null) => {
        const { data: response} = await httpService.get(`users/${match.params.id}`);
        const user = {
            ...response.data,
            soutiens_count: response.soutiens_count
        };
        setuser(user);
        cb();
        return user;
    };

    useEffect(() => {

        try {
            fetchUser(match.params.id, async () => {
                setFetching(false);
            });

        } catch (e) {
            throw e;
        }

    }, []);

    if(fetching) {
        return <FecthingSpinner />
    }

    return (
        <div className="container-fluid">
            <AppBreadcrumb title={`Utilisateur: ${getUserFullName(user, true)}`}>
                <div className="col-md-6 col-sm-12 text-right hidden-xs">
                    <Link to="/users">
                        <button className="btn btn-sm btn-default">
                            <i className="fa fa-arrow-left"></i>{' '}
                            <span>Retour</span>
                        </button>
                    </Link>
                </div>
            </AppBreadcrumb>

            <div className="row clearfix">
                <div className="col-md-12">
                    <div className="card social">
                        <div className="profile-header d-flex justify-content-between justify-content-center">
                            <div className="d-flex">
                                <div className="mr-3">
                                    <img
                                        className="rounded"
                                        src={user?.profile_picture || MyOpinionLogo}
                                        height="128px"
                                        width="128px"
                                        alt="Avatar" />
                                </div>
                                <div className="details"><h5 className="mb-0">
                                    {getUserFullName(user)}
                                </h5>
                                    <span className="text-light">@{user.username}</span>
                                    <p className="mb-0">
                                        <span>Posts: <strong>{user.posts_count || 0}</strong></span>{' '}
                                        <br/>
                                        <span>Réactions: <strong>{user.soutiens_count || 0}</strong></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-3">
                    <div className="list-group">
                        <Link to={`/users/${match.params.id}`} className={classnames({
                            'list-group-item list-group-item-action': true,
                            'active':  window.location.pathname === `/users/${user.username}`
                                ? "active"
                                : ""
                        })}>
                            <i className="fa fa-user"></i>{' '}
                            <span>Profil</span>
                        </Link>
                        <Link to={`/users/${user.username}/posts`} className={classnames({
                            'list-group-item list-group-item-action': true,
                            'active':  window.location.pathname === `/users/${user.username}/posts`
                                ? "active"
                                : ""
                        })}>
                            <i className="fa fa-pencil"></i>{' '}
                            <span>Posts ({user.posts_count || 0})</span>
                        </Link>

                        <Link to={`/users/${match.params.id}/comments`} className={classnames({
                            'list-group-item list-group-item-action': true,
                            'active':  window.location.pathname === `/users/${user.username}/comments`
                                ? "active"
                                : ""
                        })}>
                            <i className="fa fa-comments"></i>{' '}
                            <span>Commentaires ({user.comments_count || 0})</span>
                        </Link>


                    </div>
                </div>
                <div className="col-md-9">
                    <Switch>
                        <Route
                            path={`/users/:id`}
                            exact
                            render={(props) => <UserProfile user={user} onDelete={onDelete} {...props} />}
                        />
                        <Route
                            path={`/users/:id/posts`}
                            render={(props) => <UserPosts user={user} {...props} />}
                        />
                        <Route
                            path={`/users/:id/comments`}
                            render={(props) => <UserComments user={user} {...props} />}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default UserDetails;