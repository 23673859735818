import React, {useState, useEffect} from 'react';
import httpService from "../../../services/http.service";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import AppOpinion from "../../Shared/AppOpinion";

const UserPosts = ({user, ...props}) => {

    const [fectching, setFectching] = useState(true);
    const [opinions, setOpinions] = useState([]);
    const [perPage, setPerPage] = useState(null);

    const fetchOpinions = async (username, cb = () => null) => {
        const { data: response } = await httpService.get(`/users/${username}/posts`);

        const opinions = (response.data || []).map(obj => {

            const attachments = [];

            (obj?.attachments || []).forEach(attach => attachments.push({
                url: attach.url,
                type: attach.type,
                thumbnail: attach.thumbnail,
            }));

            return {
                ...obj,
                attachments
            }

        });

        setOpinions(opinions);
        setPerPage(response.meta.per_page);
        cb();
    }

    useEffect(() => {

        try {
            fetchOpinions(user.username, () => {
                setFectching(false);
            });
        } catch (e) {
            throw e;
        }

    }, []);

    if(fectching) {
        return <FecthingSpinner />
    }

    return (
        <div className="row clearfix">
            <div className="col-md-12">
                <div className="card">
                    <div className="header">
                        <h2>
                            {`${perPage} derniers posts`}
                        </h2>
                    </div>
                    <div className="testimonials">
                        {opinions.map(opinion => (
                            <AppOpinion
                                user={user}
                                opinion={opinion}
                                key={opinion.id}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default UserPosts;