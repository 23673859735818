import React from 'react';
import {getUserFullName} from "../../../utils/index";
import * as jwtService from './../../../services/jwt.service'

const UserProfile = ({user, onDelete = () => null, togglePublicPostAllowed = () => null, onRestoreAuthorisation = () => null, ...props}) => {

    const authUser = jwtService.get();

    return (
        <>
            <div className="row">
                <div className="card">
                    <div className="body">
                        <small className="text-muted">Identifiant</small>
                        <p>#{user.id}</p>
                        <small className="text-muted">Nom d'utilisateur</small>
                        <p>@{user.username}</p>
                        <small className="text-muted">Nom complet</small>
                        <p>{user.full_name}</p>
                        <small className="text-muted">Nom complet</small>
                        <p>{getUserFullName(user)}</p>
                        <small className="text-muted">Email</small>
                        <p>{user.email}</p>
                        <small className="text-muted">Statut du profil</small>
                        <p>
                            {!!user.activated_at ? (
                                <span className="badge badge-success" title="Actif">Actif</span>
                            ) : (
                                <span className="badge badge-danger" title="Inactif">Inactif</span>
                            )}
                        </p>
                        <small className="text-muted">Membre depuis le</small>
                        <p>{user.created_at}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserProfile;