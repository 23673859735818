import React, {useState} from "react";
import {Link, useRouteMatch} from "react-router-dom";
import MyOpinionLogo from "../../assets/images/MyOpinion_Logo_Head.png";
import {truncate} from "../../utils";
import {getUserFullName} from "../../utils/index";
import httpService from "../../services/http.service";

const AppComment = ({user, comment: data, toggleMute = () => null, shouldTruncate = true, truncateLength = 200, ...props}) => {

    const [comment, setComment] = useState(data);

    const routeMatch = useRouteMatch();

    const handleToggle = async (comment) => {
        if(!window.confirm('Confirmer ?')) return;

        try {
            await httpService.put(`/comments/${comment.id}/toggleMute`);
            setComment(comment => ({
                ...comment,
                mute: !comment.mute
            }));
        } catch (e) {
            throw e;
        }

    }

    return (
        <div className="card mb-1">
            <div className="body">
                <div className="bs-example" data-example-id="media-alignment">
                    <div className="media" key={comment.id}>
                        <div className="media-left">
                            <Link to={`/users/${user.username}`}>
                                <img alt="" className="media-object rounded" src={user?.profile_picture || MyOpinionLogo} width="64" height="64" />
                            </Link>
                        </div>
                        <div className="media-body">
                            <h4 className="media-heading">
                                {getUserFullName(user, true)}
                                <small className="float-right text-muted">{comment.created_at}</small>
                                {comment?.deleted_at && (
                                    <>
                                    <span className="float-right badge badge-danger" style={{cursor: "pointer"}}>
                                    <span>Commentaire supprimé</span>{' '}
                                        <i className="fa fa-ban"></i>
                                    </span>
                                    </>
                                )}
                            </h4>
                            <p>{comment.content
                                ? (shouldTruncate ? truncate(comment.content, truncateLength) : comment.content)
                                : '-'}</p>
                            <nav className="d-flex text-muted font-weight-bold">
                                            <span className="icon mr-3" title="Reponses" style={{cursor: "pointer"}}>
                                                <i className="fa fa-reply"></i>{' '}{comment.comment || 0}
                                            </span>
                                <span className="icon mr-3" title="Reactions" style={{cursor: "pointer"}}>
                                                <i className="fa fa-heart"></i>{' '}{(comment.like + comment?.dislike) || 0}
                                            </span>
                                {comment.mute ? (
                                    <span className="icon mr-3 text-success" onClick={() => handleToggle(comment)} style={{cursor: 'pointer'}}>
                                                <i className="fa fa-volume-up"></i>{' '}Unmute
                                            </span>
                                ) : (
                                    <span className="icon mr-3 text-danger" onClick={() => handleToggle(comment)} style={{cursor: 'pointer'}}>
                                                <i className="fa fa-volume-off"></i>{' '}Mute
                                            </span>
                                )}


                                {routeMatch.url != `/opinions/${comment.model_id}` && (
                                    <Link className="text-muted ml-auto" to={`/opinions/${comment.model_id}`}>
                                        <i className="fa fa-eye"></i>{' '}Voir le post
                                    </Link>
                                )}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppComment;