import {USER_TYPE_PAGE, USER_TYPE_USER, ROLE_ADMIN, ROLE_MODERATOR, ROLE_ROOT} from "../../utils/index";
import * as jwtService from '../../services/jwt.service';

const jwtToken = jwtService.get();

const role = jwtToken
	? jwtToken['isRoot'] ? ROLE_ROOT : (jwtToken['isAdmin'] ? ROLE_ADMIN : ROLE_MODERATOR)
	: null;


const formatSubMenu = (index, baseMenu = []) => {
	let startIndex = Number.parseFloat(index);

	return baseMenu
		.filter(obj => {
			switch (role) {
				case ROLE_ROOT:
					return obj['root'] === true;
				case ROLE_ADMIN:
					return obj['admin'] === true;
				case ROLE_MODERATOR:
					return obj['moderator'] === true;
			}
		})
		.map(obj => {
			const {root, admin, moderator, ...rest} = obj;
			startIndex = startIndex + 0.1;
			return {
				id: startIndex.toString(),
				...rest,
			}
		})
}

const formatMenu = (menu = []) => {

	let startIndex = 0;

	return menu
		.filter(obj => {

			if(obj['myopinion_only'] && process.env.REACT_APP_SOCIAL_HUB) return false;

			if(obj['socialhub_only'] && !process.env.REACT_APP_SOCIAL_HUB) return false;

			switch (role) {
				case ROLE_ROOT:
					return obj['root'] === true;
				case ROLE_ADMIN:
					return obj['admin'] === true;
				case ROLE_MODERATOR:
					return obj['moderator'] === true;
			}
		})
		.map(obj => {
			const {root, admin, moderator, socialhub, ...rest} = obj;
			startIndex = startIndex + 1;
			return {
				id: startIndex.toString(),
				...rest,
			}
		});
}

const metisMenu = formatMenu([
	{
		"icon": "icon-home",
		"label": "Tableau de bord",
		"to": "/",
		"root": true,
		"admin": true,
		"moderator": true,
	},

	{
		"icon": "icon-paper-clip",
		"label": process.env.REACT_APP_APP_NAME,
		"content": [
			{
				"label": "Utilisateurs",
				"to": `/users`,
				"root": true,
				"admin": true,
				"moderator": true,
			},
			{
				"label": "Posts",
				"to": "/opinions",
				"root": true,
				"admin": true,
				"moderator": true,
			},
			{
				"label": "Commentaires",
				"to": "/comments",
				"root": true,
				"admin": true,
				"moderator": true,
			},
		],
		"root": true,
		"admin": true,
		"moderator": true,
	},

	{
		"icon": "icon-lock",
		"label": "Securité",
		"content": formatSubMenu(5, [
			{
				"label": "Moderateurs",
				"to": "/moderateurs",
				"root": true,
				"admin": false,
				"moderator": false,
			},
		]),
		"root": true,
		"admin": true,
		"moderator": true,
	},
]);

export default metisMenu	