import React from "react";

const UserCardFooter = ({user}) => {
    return (
        <div className="card-footer bg-light text-center">
            <div className="row clearfix">
                <div className="col-6 font-16">
                    <i className="fa fa-thumbs-up"></i>
                    <div>
                        <span className="text-muted">{user.posts_count || 0} Réactions</span>
                    </div>
                </div>
                <div className="col-6 font-16">
                    <i className="fa fa-pencil"></i>
                    <div>
                        <span className="text-muted">{user.posts_count || 0} Posts</span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default UserCardFooter;